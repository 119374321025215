.social-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.social-buttons .p-button i {
    line-height: 2.25rem;
}

@media screen and (max-width: 960px) {
    .social-buttons .p-button:not(.p-button-icon-only) {
        display: flex;
        width: 100%;
    }

    .social-buttons .p-buttonset .p-button {
        margin-bottom: 0;
    }
}